import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #fff;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;

`;

export const AmountContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: center;
  justify-content: center;
  align-items: center;
  //border: 2px solid #;
  border-radius: 10px;
  //box-shadow: 0px 0px 3px 0px #A673EF;
`;
export const TextTitle = styled.p`
  font-family: Minecraft;

  color: var(--primary-text);
  line-height: 1.6;
  text-align: center;
  @media (max-width: 565px) {
    font-size: 25px;
    text-align: center;
    letter-spacing: 10px;
  }
  
`;
export const Icons = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover {
      transform: scale(1.5);
      transition: transform 0.5s;
    }
`;
