import React, { useEffect, useState, useRef } from "react";
import * as s from "./styles/globalStyles.js";
import styled from "styled-components";
import { createScenelogo } from './SceneLogo.js';




export const CTNButton2 = styled.button`
line-height: 1.6;
outline: none;
background-color: transparente;
color: white;
text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
display: flex;
align-items: center;
justify-content: center;


  @keyframes letter-by-letter {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes color-change {
  0% {
    color: white;
  }
  10% {
    color: rgb(255, 0, 0); /* Rojo */
  }
  20% {
    color: rgb(0, 255, 0); /* Verde */
  }
  30% {
    color: rgb(0, 0, 255); /* Azul */
  }
  40% {
    color: rgb(255, 255, 0); /* Amarillo */
  }
  50% {
    color: rgb(255, 0, 255); /* Magenta */
  }
  60% {
    color: rgb(0, 255, 255); /* Cian */
  }
  70% {
    color: rgb(128, 128, 128); /* Gris */
  }
  80% {
    color: rgb(255, 128, 0); /* Naranja */
  }
  90% {
    color: rgb(128, 0, 255); /* Morado */
  }
  100% {
    color: white;
  }
}
`;
export const CTNButton4 = styled.button`
line-height: 1.6;
outline: none;
background-color: transparent;
color: white;

text-shadow: 6px 6px 6px rgba(0, 0, 0, 1);
align-items: center;
justify-content: center;
`;
export const ResponsiveWrapper = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 1000px;
  outline: none;
`;
export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  outline: none;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  
  background-color : #252525;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;
export const StyledImg = styled.img` 
  width: 450px;
  border-radius: 0px;
  outline: none;


@keyframes Hidden {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Visible {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

  &.slide-in {
    animation: slideRight 2.1s linear infinite;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(0);
    }
    90% {
      transform: translateX(170px);
    }
  }


`;




function App() {


const WebsiteLink = "http://conquestofparadise.io/";

const DiscordLink = "https://discord.gg/conquestofparadise";
const openseaLink = "https://opensea.io/collection/conquest-of-paradise/overview";
const EtherLink = "https://etherscan.io/address/0x3b2ca2f2c1bbcb523e06820180c1345377ac4eae";
const TwitterLink = "https://x.com/ConqOfParadise";
const TiktokLink = "https://www.tiktok.com/@conqofparadise";
const InstagramLink = "https://www.instagram.com/conqofparadise/";


const TwitterHerobrineLink = "https://x.com/H3r0br1n3_";


const fortime1 = "Attention! time is running out, visit our website and participate in our Whitelist.";
const fortime2 = "Attention, the Priv-Sale is now Open on Opensea.";

const targetDate = new Date('2024-12-21T20:31:00Z');
const [timeRemaining, setTimeRemaining] = useState(targetDate.getTime() - new Date().getTime());
const intervalRef = useRef(null); 
  
 useEffect(() => {
  intervalRef.current = setInterval(() => {
        const newTimeRemaining = targetDate.getTime() - new Date().getTime();
  
        if (newTimeRemaining <= 0) {

          clearInterval(intervalRef.current);
          setTimeRemaining(0);
          return;
        }
        setTimeRemaining(newTimeRemaining);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }, []);
  
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000); 

  
const imageContainerRef = useRef(null);

useEffect(() => {
  const imageContainer = imageContainerRef.current;

  if (imageContainer) {
    const handleContextMenu = (e) => {
      if (e.target.nodeName === 'IMG') {
        e.preventDefault();
      }
    };
    imageContainer.addEventListener('contextmenu', handleContextMenu);
  }
}, [imageContainerRef]);




const audio2 = new Audio('/config/sounds/click.mp3');




const canvasReflogo = useRef(null);
const [sceneDatalogo, setSceneDatalogo] = useState(null);


useEffect(() => {
  const canvasContainer = canvasReflogo.current;
  if (canvasContainer) {
    const { scene, renderer } = createScenelogo(canvasContainer);
    setSceneDatalogo({ scene, renderer });
  }
}, [canvasReflogo]);


const [hovered1, setHovered1] = useState(false);
const [hovered2, setHovered2] = useState(false);
const [hovered3, setHovered3] = useState(false);
const [hovered4, setHovered4] = useState(false);
const [hovered5, setHovered5] = useState(false);  
const [hovered6, setHovered6] = useState(false);
const [hovered7, setHovered7] = useState(false);
const [hovered8, setHovered8] = useState(false);
const [hovered9, setHovered9] = useState(false);

const handleMouseEnter1 = () => {   setHovered1(true); }; const handleMouseLeave1 = () => {   setHovered1(false); };
const handleMouseEnter2 = () => {   setHovered2(true); }; const handleMouseLeave2 = () => {   setHovered2(false); };
const handleMouseEnter3 = () => {   setHovered3(true); }; const handleMouseLeave3 = () => {   setHovered3(false); };
const handleMouseEnter4 = () => {   setHovered4(true); }; const handleMouseLeave4 = () => {   setHovered4(false); };
const handleMouseEnter5 = () => {   setHovered5(true); }; const handleMouseLeave5 = () => {   setHovered5(false); };
const handleMouseEnter6 = () => {   setHovered6(true); }; const handleMouseLeave6 = () => {   setHovered6(false); };
const handleMouseEnter7 = () => {   setHovered7(true); }; const handleMouseLeave7 = () => {   setHovered7(false); };
const handleMouseEnter8 = () => {   setHovered8(true); }; const handleMouseLeave8 = () => {   setHovered8(false); };
const handleMouseLeave9 = () => {   setHovered9(false); }; const handleMouseEnter9 = () => {   setHovered9(true); };

const [IsHovered1, setIsHovered1] = useState(false);
const [IsHovered2, setIsHovered2] = useState(false);
const [IsHovered3, setIsHovered3] = useState(false);
const [IsHovered4, setIsHovered4] = useState(false);
const [IsHovered5, setIsHovered5] = useState(false);  
const [IsHovered6, setIsHovered6] = useState(false);
const [IsHovered7, setIsHovered7] = useState(false);
const [IsHovered8, setIsHovered8] = useState(false);
const [IsHovered9, setIsHovered9] = useState(false);

const handleTouchStart1 = () => {setIsHovered1(true); }; const handleTouchEnd1 = () => { setIsHovered1(false); };
const handleTouchStart2 = () => {setIsHovered2(true); }; const handleTouchEnd2 = () => {setIsHovered2(false); };
const handleTouchStart3 = () => {setIsHovered3(true); }; const handleTouchEnd3 = () => {setIsHovered3(false); };
const handleTouchStart4 = () => {setIsHovered4(true); }; const handleTouchEnd4 = () => {setIsHovered4(false); };
const handleTouchStart5 = () => {setIsHovered5(true); }; const handleTouchEnd5 = () => {setIsHovered5(false); };
const handleTouchStart6 = () => {setIsHovered6(true); }; const handleTouchEnd6 = () => {setIsHovered6(false); };
const handleTouchStart7 = () => {setIsHovered7(true); }; const handleTouchEnd7 = () => {setIsHovered7(false); };
const handleTouchStart8 = () => {setIsHovered8(true); }; const handleTouchEnd8 = () => {setIsHovered8(false); };
const handleTouchEnd9 = () => {setIsHovered9(false); }; const handleTouchStart9 = () => {setIsHovered9(true); };






function ajustarEscaladoDispositivos() {
  const anchoVentana = window.innerWidth;
  const altoVentana = window.innerHeight;


  const anchoPromedioLaptop = 1366;
  const altoPromedioLaptop = 768;
  const anchoPromedioMac = 1440;
  const altoPromedioMac = 900;
  const anchoPromedioPC = 1920; 
  const altoPromedioPC = 1080;  


  const esMac = navigator.userAgent.includes("Mac"); 
  const esPC = !esMac && anchoVentana >= anchoPromedioPC && altoVentana >= altoPromedioPC;


  const escalaLaptop = 0.7; 
  const escalaMac = 0.7;    
  const escalaPC = 1.2  ;    


  let escalaFinal;
  if (esMac) {
    escalaFinal = escalaMac;
  } else if (esPC) {
    escalaFinal = escalaPC;
  } else {
    escalaFinal = escalaLaptop;
  }
  const escalaLimitada = Math.max(0.5, Math.min(1.2, escalaFinal)); 
  document.body.style.transform = `scaleX(${escalaLimitada}) scaleY(${escalaLimitada})`;
}

function ajustarEscaladoMovil() {
  const anchoVentana = window.innerWidth;
  const escala = Math.max(0.32, Math.min(1, (anchoVentana - 395) / 625)); 
  document.body.style.transform = `scaleX(${escala}) scaleY(${escala})`;
}


const mediaQuery = window.matchMedia("(max-width: 768px)"); 
function handleMediaQueryChange(e) {
  if (e.matches) { 
    window.addEventListener('resize', ajustarEscaladoMovil);
    ajustarEscaladoMovil();
  } else {
    window.addEventListener('resize', ajustarEscaladoDispositivos);
    ajustarEscaladoDispositivos();
  }
}

handleMediaQueryChange(mediaQuery);
mediaQuery.addListener(handleMediaQueryChange);




function cambiarImagenFondo() {
  document.body.style.backgroundImage = "url(/config/images/background.png)";
}
setTimeout(cambiarImagenFondo, 2000);


setTimeout(() => {
  document.body.style.transition = 'opacity 4S ease'; 
  document.body.style.opacity = 1;
}, 5); 



document.addEventListener("dragstart", function(event) {
  if (event.target.tagName === "img") {
    event.preventDefault();
  }
});
  



  












  function isMobile() {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
  

  if (isMobile()) {
    return (

<div ref={imageContainerRef}>


<s.Container flex={1} ai={"center"}
style= {{ padding: 24, position: "absolute", top: 100, left: 0, width: "100%", height: "120%" }}>
    
<ResponsiveWrapper flex={1} style={{ padding: 220, position: "absolute"}} test > 

<s.Container translate="no" style={{  position: "absolute", top: -190, left: 75, pointerEvents: 'none' }}>

<StyledImg src={"/config/images/time.png"}
style={{ position: "absolute", top: 1716, left: -50, width: "950px",height: "350px" }} 
alt="image" draggable="false"/>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 2012, fontSize: 24}} >
 <p  translate="no" style={{position: "relative", top: -4, left: 28, display: 'flex', justifyContent: 'center', width: "160px"}}>DAY</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "169px"}}>HOUR</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>MIN</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "168px"}}>SEC</p>  
</CTNButton2>


{timeRemaining <= 0 ? ( 
<>
<CTNButton4 
style={{ position: "absolute", top: 1745, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime2}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1858, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>UP</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>DA</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>TI</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>NG</p>  
</CTNButton2>

</>
) : (
<>
<CTNButton4 
style={{ position: "absolute", top: 1745, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime1}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1858, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>{days}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>{hours}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>{minutes}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>{seconds}</p>  
</CTNButton2>
</>
)}




</s.Container>

<ResponsiveWrapper translate="no"  flex={1} style={{padding: 24, position: "absolute", top: 470, }} test
>

<s.Container flex={1} jc={"center"} ai={"center" }>

    <StyledImg
      src={"/config/images/More/TestBaseMovil.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -70, left: -100, width: "1200px", height: "1150px" }} />

<StyledImg
      src={"/config/images/acept.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 747, left: 75, width: "850px", height: "80px" }} />

<StyledImg
      src={"/config/images/acept.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 10, left: 75, width: "850px", height: "80px" }} />


<CTNButton4 
style={{ position: "absolute", top: 20, left: 88, fontSize: 30, width: "825px", height: "65px", pointerEvents: 'none'  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 23}}>CONQUEST OF PARADISE SOCIAL NETWORKS.</p>  
</CTNButton4>

<CTNButton4 
style={{ position: "absolute", top: 756, left: 88, fontSize: 30, width: "825px", height: "65px", pointerEvents: 'none'  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 23}}>HEROBRINE SOCIAL NETWORKS.</p>  
</CTNButton4>



<CTNButton2
style={{ position: "absolute", top: 165, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered1 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart1()}   onTouchEnd={() => handleTouchEnd1()}
onClick={(e) => { window.open(WebsiteLink, "_blank"); audio2.play(); }}>
WEBSITE
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 305, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered2 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart2()}   onTouchEnd={() => handleTouchEnd2()}
onClick={(e) => { window.open(DiscordLink, "_blank"); audio2.play(); }}>
DISCORD
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 444, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered3 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart3()}   onTouchEnd={() => handleTouchEnd3()}
onClick={(e) => { window.open(TiktokLink, "_blank"); audio2.play(); }}>
TIKTOK
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 165, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered4 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart4()}   onTouchEnd={() => handleTouchEnd4()}
onClick={(e) => { window.open(openseaLink, "_blank"); audio2.play(); }}>
OPENSEA
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 305, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered5 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart5()}   onTouchEnd={() => handleTouchEnd5()}
onClick={(e) => { window.open(TwitterLink, "_blank"); audio2.play(); }}>
TWITTER
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 444, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered9 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart9()}   onTouchEnd={() => handleTouchEnd9()}
onClick={(e) => { window.open(InstagramLink, "_blank"); audio2.play(); }}>
INSTAGRAM
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 590, left: 272, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered6 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart6()}   onTouchEnd={() => handleTouchEnd6()}
onClick={(e) => { window.open(EtherLink, "_blank"); audio2.play(); }}>
ETHERSCAN
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 902, left: 272, width: "440px", height: "92px"}}>
<StyledImg
src={IsHovered7 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onTouchStart={() => handleTouchStart7()}   onTouchEnd={() => handleTouchEnd7()}
onClick={(e) => { window.open(TwitterHerobrineLink, "_blank"); audio2.play(); }}>
TWITTER
</CTNButton2>
</CTNButton2>


  </s.Container>



</ResponsiveWrapper>



<ResponsiveWrapper flex={1} style={{ position: "absolute"}} test > 


<canvas ref={canvasReflogo} alt="CONQUEST OF PARADISE SOCIAL NETWORKS" style={{ position: "absolute", top: -190, left: -95, pointerEvents: 'none' }} />

</ResponsiveWrapper>

<s.Container translate="no" style={{ position: "absolute", top: -240, left: 75, pointerEvents: 'none'   }}>


<StyledImg
src={"/config/images/timeout.png"}
      alt="image"
      draggable="false"
style={{ position: "absolute", top: 2270, left: -50, width: "950px",height: "130px" }} />

<CTNButton4 
style={{ position: "absolute", top: 2304, left: -30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 18}}>CONQUEST OF PARADISE IS AN INDEPENDENT PROJECT INSPIRED BY MINECRAFT.</p>  
</CTNButton4>

</s.Container>






</ResponsiveWrapper> 





      
  </s.Container>




    </div>  
);
}


else {
  return (
<div ref={imageContainerRef}>


<s.Container flex={1} ai={"center"}
style= {{ padding: 24, position: "absolute", top: 100, left: 0, width: "100%", height: "120%" }}>
    
<ResponsiveWrapper flex={1} style={{ padding: 220, position: "absolute"}} test > 

<s.Container translate="no" style={{ position: "absolute", top: -460, left: 75, pointerEvents: 'none' }}>

<StyledImg src={"/config/images/time.png"}
style={{ position: "absolute", top: 1716, left: -50, width: "950px",height: "350px" }} 
alt="image" draggable="false"/>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 2012, fontSize: 24}} >
 <p  translate="no" style={{position: "relative", top: -4, left: 28, display: 'flex', justifyContent: 'center', width: "160px"}}>DAY</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "169px"}}>HOUR</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>MIN</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "168px"}}>SEC</p>  
</CTNButton2>


{timeRemaining <= 0 ? ( 
<>
<CTNButton4 
style={{ position: "absolute", top: 1740, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime2}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1858, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>UP</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>DA</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>TI</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>NG</p>  
</CTNButton2>

</>
) : (
<>
<CTNButton4 
style={{ position: "absolute", top: 1740, left: -30, fontSize: 30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p className="animated-text-2" style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 32}}>{fortime1}</p>  
</CTNButton4>

<CTNButton2
style={{ transform: 'scale(0.95)', position: "absolute", top: 1858, fontSize: 90}}>

 <p  translate="no" style={{position: "relative",   left: 28, display: 'flex', justifyContent: 'center', width: "167px"}}>{days}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 75, display: 'flex', justifyContent: 'center', width: "167px"}}>{hours}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 120, display: 'flex', justifyContent: 'center', width: "167px"}}>{minutes}</p>  
 <p  translate="no" style={{position: "relative", top: -4, left: 165, display: 'flex', justifyContent: 'center', width: "167px"}}>{seconds}</p>  
</CTNButton2>
</>
)}




</s.Container>

<ResponsiveWrapper translate="no"  flex={1} style={{padding: 24, position: "absolute",}} test
>

<s.Container flex={1} jc={"center"} ai={"center" }>

    <StyledImg
      src={"/config/images/More/TestBaseMovil.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: -70, left: -100, width: "1200px", height: "1150px" }} />

<StyledImg
      src={"/config/images/acept.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 747, left: 75, width: "850px", height: "80px" }} />

<StyledImg
      src={"/config/images/acept.png"}
      alt="image"
      draggable="false"
      style={{ position: "absolute", top: 10, left: 75, width: "850px", height: "80px" }} />


<CTNButton4 
style={{ position: "absolute", top: 20, left: 88, fontSize: 30, width: "825px", height: "65px", pointerEvents: 'none'  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 23}}>CONQUEST OF PARADISE SOCIAL NETWORKS.</p>  
</CTNButton4>

<CTNButton4 
style={{ position: "absolute", top: 756, left: 88, fontSize: 30, width: "825px", height: "65px", pointerEvents: 'none'  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 23}}>HEROBRINE SOCIAL NETWORKS.</p>  
</CTNButton4>



<CTNButton2
style={{ position: "absolute", top: 165, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={hovered1 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter1()}   onMouseLeave={() => handleMouseLeave1()}
onClick={(e) => { window.open(WebsiteLink, "_blank"); audio2.play(); }}>
WEBSITE
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 305, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={hovered2 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter2()}   onMouseLeave={() => handleMouseLeave2()}
onClick={(e) => { window.open(DiscordLink, "_blank"); audio2.play(); }}>
DISCORD
</CTNButton2>
</CTNButton2>

<CTNButton2
style={{ position: "absolute", top: 444, left: 11, width: "440px", height: "92px"}}>
<StyledImg
src={hovered3 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter3()}   onMouseLeave={() => handleMouseLeave3()}
onClick={(e) => { window.open(TiktokLink, "_blank"); audio2.play(); }}>
TIKTOK
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 165, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={hovered4 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter4()}   onMouseLeave={() => handleMouseLeave4()}
onClick={(e) => { window.open(openseaLink, "_blank"); audio2.play(); }}>
OPENSEA
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 305, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={hovered5 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter5()}   onMouseLeave={() => handleMouseLeave5()}
onClick={(e) => { window.open(TwitterLink, "_blank"); audio2.play(); }}>
TWITTER
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 444, left: 540, width: "440px", height: "92px"}}>
<StyledImg
src={hovered9 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter9()}   onMouseLeave={() => handleMouseLeave9()}
onClick={(e) => { window.open(InstagramLink, "_blank"); audio2.play(); }}>
INSTAGRAM
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 590, left: 272, width: "440px", height: "92px"}}>
<StyledImg
src={hovered6 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter6()}   onMouseLeave={() => handleMouseLeave6()}
onClick={(e) => { window.open(EtherLink, "_blank"); audio2.play(); }}>
ETHERSCAN
</CTNButton2>
</CTNButton2>


<CTNButton2
style={{ position: "absolute", top: 902, left: 272, width: "440px", height: "92px"}}>
<StyledImg
src={hovered7 ? "/config/images/red1.png" : "/config/images/red0.png"}
style={{ position: "absolute", top: -15, left: -13, width: "471px", height: "120px"  }}
alt="image" draggable="false" />
<CTNButton2
style={{ position: "absolute", fontSize: 43, top: -0, left: 2, width: "440px", height: "92px"}}
onMouseEnter={() => handleMouseEnter7()}   onMouseLeave={() => handleMouseLeave7()}
onClick={(e) => { window.open(TwitterHerobrineLink, "_blank"); audio2.play(); }}>
TWITTER
</CTNButton2>
</CTNButton2>

  </s.Container>



</ResponsiveWrapper>



<ResponsiveWrapper flex={1} style={{ position: "absolute"}} test > 


<canvas ref={canvasReflogo} alt="CONQUEST OF PARADISE SOCIAL NETWORKS" style={{ position: "absolute", top: -360, left: -95, pointerEvents: 'none' }} />

</ResponsiveWrapper>

<s.Container translate="no" style={{ position: "absolute", top: -640, left: 75, pointerEvents: 'none'   }}>


<StyledImg
src={"/config/images/timeout.png"}
      alt="image"
      draggable="false"
style={{ position: "absolute", top: 2270, left: -50, width: "950px",height: "130px" }} />

<CTNButton4 
style={{ position: "absolute", top: 2304, left: -30, border: '0px solid #ddd', width: "907px", height: "65px"  }}>
<p style={{position: "relative", display: 'flex',  justifyContent: 'center', fontSize: 18}}>CONQUEST OF PARADISE IS AN INDEPENDENT PROJECT INSPIRED BY MINECRAFT.</p>  
</CTNButton4>

</s.Container>






</ResponsiveWrapper> 





      
  </s.Container>




    </div>  
 
  );
}
}





export default App;
