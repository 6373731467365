import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export function createScenelogo(canvasContainer) {

  
  
// Escena, cámara, renderizador
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(25, 100 / 135, 0.1, 100);
scene.add(camera);
camera.position.set(0, 0, 5);
camera.lookAt(new THREE.Vector3()); 

const renderer = new THREE.WebGLRenderer({ alpha: true, canvas: canvasContainer, antialias: true, });


renderer.setSize(1200, 300);

  function resizeRenderer() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

  }

// Controles de órbita
const orbitControls = new OrbitControls(camera, renderer.domElement);
orbitControls.enableZoom = false;
orbitControls.enableDamping = true;



// Anima la escena
const animate = () => {
  orbitControls.update();
  renderer.render(scene, camera);
  requestAnimationFrame(animate);
};
animate();



// Cargar modelo 3D
const gltfloader = new GLTFLoader();
gltfloader.load("./model/logo.gltf", (gltf) => {
  const mesh = gltf.scene;
  scene.add(mesh);
  mesh.scale.set(0.04, 0.44, 0.1);
  mesh.position.set(0, 0, 0);
  
  

  

  // Obtener los huesos de la cabeza y el cuerpo
  const headBone = scene.getObjectByName("Had1");
  const bodyBone = scene.getObjectByName("body1");

  // **Movimiento del cursor para el movimiento del objeto:**
  let dragging = true;
  let lastX = 0;
  let lastY = 0;

  document.addEventListener("mousedown", (event) => {
    dragging = false;
    lastX = event.clientX;
    lastY = event.clientY;
  });

  document.addEventListener("mouseup", () => {
    dragging = true;
  });

  document.addEventListener("mousemove", (event) => {
    if (dragging && mesh && headBone && bodyBone) {

      
      const deltaX = event.clientX - lastX;
      const deltaY = event.clientY - lastY;

    // **Variable auxiliar para la rotación deseada**
    const desiredRotationX = headBone.rotation.x - deltaY * 0.03;
    

    // **Suavizado de la rotación**
    headBone.rotation.x -= (desiredRotationX - headBone.rotation.x) * 0.1;

    // Limitar la rotación de la cabeza
    headBone.rotation.y += deltaX * 0.0005  ;
    bodyBone.rotation.x += deltaY * 0;
    headBone.rotation.y = Math.max(-Math.PI / 25, Math.min(Math.PI / 25, headBone.rotation.y));
    headBone.rotation.x = Math.max(-Math.PI / 19, Math.min(Math.PI / 19, headBone.rotation.x));

      // **Movimiento del cuerpo:**
      bodyBone.rotation.y += deltaX * 0.0013;
      bodyBone.rotation.x += deltaY * 0;
      bodyBone.rotation.y = Math.max(-Math.PI / 4, Math.min(Math.PI / 4, bodyBone.rotation.y));
      bodyBone.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, bodyBone.rotation.x));

      lastX = event.clientX;
      lastY = event.clientY
    } 
  });

    // **Movimiento del dedo para el movimiento del objeto:**
let isDragging = false;
let lastTouchX = 0;
let lastTouchY = 0;
let desiredRotationX = 0; // For rotation smoothing

const handleTouchStart = (event) => {
  isDragging = true;
  lastTouchX = event.touches[0].clientX;
  lastTouchY = event.touches[0].clientY;
};

const handleTouchMove = (event) => {
  if (isDragging && mesh && (headBone || bodyBone)) { // Check for bones if applicable

    const deltaX = event.touches[0].clientX - lastTouchX;
    const deltaY = -(event.touches[0].clientY - lastTouchY); 

    // **Rotation Smoothing:**
    desiredRotationX -= deltaY * 0.03;
    desiredRotationX = Math.max(-Math.PI / 10, Math.min(Math.PI / 10, desiredRotationX));

    headBone.rotation.x += (desiredRotationX - headBone.rotation.x) * 0.1; // Smoothly approach desired rotation

    // Limit rotation (if using bones)
    if (headBone) {
      headBone.rotation.y += deltaX * 0.0005;
      bodyBone.rotation.x += deltaY * 0;
      headBone.rotation.y = Math.max(-Math.PI / 25, Math.min(Math.PI / 25, headBone.rotation.y));
      headBone.rotation.x = Math.max(-Math.PI / 10, Math.min(Math.PI / 10, headBone.rotation.x));
    }
    if (bodyBone) {
      bodyBone.rotation.y += deltaX * 0.0013;
      bodyBone.rotation.x += deltaY * 0;
      bodyBone.rotation.y = Math.max(-Math.PI / 4, Math.min(Math.PI / 4, bodyBone.rotation.y));
      bodyBone.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, bodyBone.rotation.x));
    }

    lastTouchX = event.touches[0].clientX;
    lastTouchY = event.touches[0].clientY;
  }
};

const handleTouchEnd = () => {
  isDragging = false;
};

// Adding event listeners for touch events
document.addEventListener("touchstart", handleTouchStart);
document.addEventListener("touchmove", handleTouchMove);
document.addEventListener("touchend", handleTouchEnd);



});

// Luz
const light = new THREE.DirectionalLight(0xffffff, 3);
light.position.set(0, 1, 1);
scene.add(light);



return { scene, renderer };

  };
